$(window).load(function () {

  if($("#index").length){
    $(window).scroll(function(){
      var top = $('#results').offset().top;//表示位置
      if($(this).scrollTop() > top){
          $("header").fadeIn();
      }else{
          $("header").fadeOut();
      }
    });
    $(window).scroll(function(){
      $(".animate_fid").each(function(index){
        var scr = $(document).scrollTop();
        var height = $(".animate_fid").eq(index).offset().top - 800;
        if(scr >= height){
          $(".animate_fid").eq(index).addClass("fadeInDown");
        }
      });
    });
    $(window).scroll(function(){
      $(".animate_fir").each(function(index){
        var scr = $(document).scrollTop();
        var height = $(".animate_fir").eq(index).offset().top - 800;
        if(scr >= height){
          $(".animate_fir").eq(index).addClass("fadeInRight");
        }
      });
    });
  }

  if($("#index").length){
    $(".slider").bxSlider({
      auto: false,
      speed :1000
    });
  }

  $("#fv .mainImg").addClass("fadeInDown");

    $("#postcode1").jpostal({
        postcode : [
          "#postcode1",
          "#postcode2"
        ],
        address : {
          "#area"  : "%3",
          "#address"  : "%4%5"
        }
    });

    $('a img, .on_off,input[type="image"]').hover(function(){
        $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
    },
    function(){
        if (!$(this).hasClass('currentPage')) {
            $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
        }
    });

    // #で始まるアンカーをクリックした場合に処理
    $('a[href^="#"]').click(function() {
        // スクロールの速度
        var speed = 800;// ミリ秒
        // アンカーの値取得
        var href= $(this).attr("href");
        // 移動先を取得
        var target = $(href == "#" || href == "" ? 'html' : href);
        var header = $("header").outerHeight(true);
        // 移動先を数値で取得
        var position = target.offset().top;
        // スムーススクロール
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
    });

    $('#price .acc .acc_title').click(function() {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
    });

    $("#form .s_box").hide();

    $("#form input[name='check[business]']").change(function() {
      if($("#form #business01:checked").val()){
        $("#form .s_box").show();
        $("#form #tel").addClass("require");
      }else{
        $("#form .s_box").hide();
        $("#form #tel").removeClass("require");
      }
    });
    $("#form").find("input[name='check[business]']").each(function() {
      if($("#form #business01:checked").val()){
        $("#form .s_box").show();
        $("#form #tel").addClass("require");
      }else{
        $("#form .s_box").hide();
        $("#form #tel").removeClass("require");
      }
    });


    $('#form .inputBtn').click(function() {
        $('#s_postcode').val($('#postcode').val());
        $('#s_area').val($('#area').val());
        $('#s_address1').val($('#address1').val());
        $('#s_address2').val($('#address2').val());
    });


    // スクロールバーの横幅を取得
    $('html').append('<div class="scrollbar" style="overflow:scroll;"></div>');
    var scrollsize = window.innerWidth - document.documentElement.clientWidth;

    $('.scrollbar').hide();

    // 「.modal-open」をクリック
    $('.modal-open').click(function(){
        // html、bodyを固定（overflow:hiddenにする）
        $('html, body').addClass('lock');

        // オーバーレイ用の要素を追加
        $('body').append('<div class="modal-overlay"></div>');

        // オーバーレイをフェードイン
        $('.modal-overlay').fadeIn('slow');

        // モーダルコンテンツのIDを取得
        var modal = '#' + $(this).attr('data-target');

         // モーダルコンテンツを囲む要素を追加
        $(modal).wrap("<div class='modal-wrap'></div>");

        // モーダルコンテンツを囲む要素を表示
        $('.modal-wrap').show();

        // モーダルコンテンツの表示位置を設定
        modalResize();

         // モーダルコンテンツフェードイン
        $(modal).fadeIn('slow');

        // モーダルコンテンツをクリックした時はフェードアウトしない
        $(modal).click(function(e){
            e.stopPropagation();
        });

        // 「.modal-overlay」あるいは「.modal-close」をクリック
        $('.modal-wrap, .modal-close').off().click(function(){
            // モーダルコンテンツとオーバーレイをフェードアウト
            $(modal).fadeOut('slow');
            $('.modal-overlay').fadeOut('slow',function(){
                // html、bodyの固定解除
                $('html, body').removeClass('lock');
                // オーバーレイを削除
                $('.modal-overlay').remove();
                // モーダルコンテンツを囲む要素を削除
                $(modal).unwrap("<div class='modal-wrap'></div>");
           });
        });

        // リサイズしたら表示位置を再取得
        $(window).on('resize', function(){
            modalResize();
        });

        // モーダルコンテンツの表示位置を設定する関数
        function modalResize(){
            // ウィンドウの横幅、高さを取得
            var w = $(window).width();
            var h = $(window).height();

            // モーダルコンテンツの横幅、高さを取得
            var mw = $(modal).outerWidth(true);
            var mh = $(modal).outerHeight(true);

            // モーダルコンテンツの表示位置を設定
            if ((mh > h) && (mw > w)) {
                $(modal).css({'left': 0 + 'px','top': 0 + 'px'});
            } else if ((mh > h) && (mw < w)) {
                var x = (w - scrollsize - mw) / 2;
                $(modal).css({'left': x + 'px','top': 0 + 'px'});
            } else if ((mh < h) && (mw > w)) {
                var y = (h - scrollsize - mh) / 2;
                $(modal).css({'left': 0 + 'px','top': y + 'px'});
            } else {
                var x = (w - mw) / 2;
                var y = (h - mh) / 2;
                $(modal).css({'left': x + 'px','top': y + 'px'});
            }
        }

    });


});